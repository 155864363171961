exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dsail-js": () => import("./../../../src/pages/dsail.js" /* webpackChunkName: "component---src-pages-dsail-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-brad-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/brad.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-brad-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-dejavid-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/dejavid.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-dejavid-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-eccs-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/eccs.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-eccs-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-factorjoin-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/factorjoin.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-factorjoin-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-logos-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/logos.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-logos-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-lucid-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/lucid.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-lucid-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-palimpzest-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/palimpzest.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-palimpzest-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-sdc-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/sdc.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-sdc-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-seed-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/seed.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-seed-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-ssms-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/ssms.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-ssms-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-stage-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/stage.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-stage-mdx" */),
  "component---src-templates-project-js-content-file-path-src-markdowns-projects-treeline-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/home/markakis/dsg-public/src/markdowns/projects/treeline.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-src-markdowns-projects-treeline-mdx" */)
}

